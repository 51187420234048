import React, { useState, useEffect } from 'react'
import { supabase } from '../supabaseClient';
import RepaymentStatusCard from '../components/cards/RepaymentStatusCard';

const LoansView = ({ userProfile }) => {
  const [loans, setLoans] = useState([]);

  useEffect(() => {
    if (userProfile && userProfile[0].venue_loan_status) {
      setLoans(userProfile[0].venue_loan_status);
    }
  }, [userProfile]);

  return (
    <div className="flex flex-col h-screen w-screen">
      <div className="container flex w-full flex-row text-center justify-center pt-5 ml-7"></div>
      <div className="flex-1 flex flex-col items-center justify-center">
      {/* <h1 className='text-white text-2xl' style={{ marginTop: "200px" }}>Loan View</h1> */}

        <div className="grid grid-cols-1 gap-5" style={{ marginTop: "250px" }}>
          {loans.map((loan, index) => (
            <RepaymentStatusCard key={index} loan={loan} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default LoansView;
