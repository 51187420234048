import { useState } from 'react'
import { supabase } from '../supabaseClient'

function Login() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            setLoading(true)
            const { data, error } = await supabase.auth.signInWithPassword({
                email,
                password,
            })
            if (error) {
                throw error
            }
        } catch (error) {
            alert(error.message)
        } finally {
            setLoading(false)
        }
    }

    const emailBorderColor = 'border-gray-700'
    const passwordBorderColor = 'border-gray-700'

    return (
        <div className="flex h-screen w-screen items-center justify-center bg-gradient-to-r from-[#7D79FF] to-[#E75881]">
            <div className="w-80 bg-gray-900 p-6 rounded-md text-white">
                <div className='mb-2'>
                    <h1 className="text-4xl font-bold ">Marley</h1>
                    <h1>Venue Login</h1>
                </div>
                {loading ? (
                    '...Loading'
                ) : (
                    <form onSubmit={handleSubmit}>
                        <label
                            className="block text-white mb-2"
                            htmlFor="email"
                        >
                            Email
                        </label>
                        <input
                            className={`w-full bg-gray-800 border ${emailBorderColor} text-white py-2 px-3 rounded mb-4`}
                            type="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <label
                            className="block text-white mb-2"
                            htmlFor="password"
                        >
                            Password
                        </label>
                        <input
                            className={`w-full bg-gray-800 border ${passwordBorderColor} text-white py-2 px-3 rounded mb-4`}
                            type="password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button className="bg-blue-600 text-white py-2 px-4 rounded w-full">
                            {' '}
                            Login{' '}
                        </button>
                        {error && (
                            <div className="text-red-500 text-xs">
                                {error.message}
                            </div>
                        )}
                        <div className="pt-4 pb-2"></div>
                    </form>
                )}
                <div className="flex align-center"></div>
            </div>
        </div>
    )
}

export default Login
