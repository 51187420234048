import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import BasicInfoForm from '../components/BasicInfoForm'

const Application = ({ userProfile }) => {
    const [isApproved, setIsApproved] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)

    const [applicationStatus, setApplicationStatus] = useState()

    const sessionId = localStorage.getItem('sessionId')

    const handleApplicationStatusChange = (value) => {
        setApplicationStatus(value)
    }

    const handleSubmit = async (applicationStatus) => {
        if (applicationStatus == 'approved') {
            setIsApproved(true)
            setIsSubmitted(true)
        } else {
            setIsApproved(false)
        }
    }

    useEffect(() => {
        const clearLocalStorageOnClose = () => {
            localStorage.removeItem('sessionId')
        }

        // Add event listener to the window object
        window.addEventListener('beforeunload', clearLocalStorageOnClose)

        // Cleanup function to remove the event listener when the component is unmounted
        return () => {
            window.removeEventListener('beforeunload', clearLocalStorageOnClose)
        }
    }, [])

    if (!isSubmitted) {
        return (
            <BasicInfoForm
                onSubmit={handleSubmit}
                userProfile={userProfile}
                onApplicationStatusChange={handleApplicationStatusChange}
            />
        )
    } else {
        return isApproved && isSubmitted ? (
            <Redirect to="/approved" />
        ) : (
            <Redirect to="/pending" />
        )
    }
}

export default Application
