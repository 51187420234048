import React from 'react'

const RepaymentStatusCard = ({ loan }) => {
    const originalTerm = parseInt(loan.original_term, 10)
    const termsRemaining = parseInt(loan.terms_remaining, 10)
    const termsPaid = originalTerm - termsRemaining

    const progressBar = Array.from({ length: originalTerm }, (_, i) => (
        <div
            key={`progress-${i}`}
            className={`h-2 ${
                i < termsPaid ? 'bg-[#EA638C]' : 'bg-gray-300'
            } rounded-sm w-full`}
            style={{ gridColumnStart: i + 1 }}
        />
    ))

    return (
        <div className="bg-white h-[300px] w-[780px] rounded-md shadow p-4">
            <div
                className={`grid grid-cols-${originalTerm} gap-1 h-4 bg-transparent rounded-t-lg -mt-4`}
            >
                {progressBar}
            </div>
            <h3 className="text-xl align-left font-semibold mt-0">
                {loan.loan_id}
            </h3>
            <hr className="mt-1 border-b-1 border-blueGray-300" />
            <div className="grid grid-cols-3 gap-4 mt-3">
                <div className="flex flex-col text-center">
                    <span className="text-sm font-semibold uppercase text-blueGray-500">
                        Loan Status
                    </span>
                    <span className="text-sm font-bold text-blueGray-700">
                        {loan.loan_status}
                    </span>
                </div>
                <div className="flex flex-col text-center">
                    <span className="text-sm font-semibold uppercase text-blueGray-500">
                        Next Payment Amount
                    </span>
                    <span className="text-sm font-bold text-blueGray-700">
                        $
                        {parseFloat(loan.next_payment_amount).toLocaleString(
                            undefined,
                            {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }
                        )}
                    </span>
                </div>
                <div className="flex flex-col text-center">
                    <span className="text-sm font-semibold uppercase text-blueGray-500">
                        Next Payment Date
                    </span>
                    <span className="text-sm font-bold text-blueGray-700">
                        {loan.next_payment_date}
                    </span>
                </div>
            </div>
            <div className="grid grid-cols-4 gap-1 mt-9">
                <div className="flex flex-col text-center">
                    <span className="text-sm font-semibold uppercase text-blueGray-500">
                        Terms Remaining
                    </span>
                    <span className="text-sm font-bold text-blueGray-700">
                        {loan.terms_remaining}
                    </span>
                </div>
                <div className="flex flex-col text-center">
                    <span className="text-sm font-semibold uppercase text-blueGray-500">
                        Original Term
                    </span>
                    <span className="text-sm font-bold text-blueGray-700">
                        {loan.original_term}
                    </span>
                </div>
                <div className="flex flex-col text-center">
                    <span className="text-sm font-semibold uppercase text-blueGray-500">
                        Total Paid
                    </span>
                    <span className="text-sm font-bold text-blueGray-700">
                        ${parseFloat(loan.total_paid).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}
                    </span>
                </div>
                <div className="flex flex-col text-center">
                    <span className="text-sm font-semibold uppercase text-blueGray-500">
                        Scheduled Payoff Date
                    </span>
                    <span className="text-sm font-bold text-blueGray-700">
                        {loan.scheduled_payoff_date}
                    </span>
                </div>
            </div>
            <div className="grid grid-cols-4 gap-1 mt-9">
                <div className="flex flex-col text-center">
                    <span className="text-sm font-semibold uppercase text-blueGray-500">
                        Name
                    </span>
                    <span className="text-sm font-bold text-blueGray-700">
                        {loan.full_name}
                    </span>
                </div>
                <div className="flex flex-col text-center">
                    <span className="text-sm font-semibold uppercase text-blueGray-500">
                        Email
                    </span>
                    <span className="text-sm font-bold text-blueGray-700">
                        {loan.email}
                    </span>
                </div>
                <div className="flex flex-col text-center">
                    <span className="text-sm font-semibold uppercase text-blueGray-500">
                        Phone
                    </span>
                    <span className="text-sm font-bold text-blueGray-700">
                        {loan.phone}
                    </span>
                </div>
                <div className="flex flex-col text-center">
                    <span className="text-sm font-semibold uppercase text-blueGray-500">
                        Address
                    </span>
                    <span className="text-sm font-bold text-blueGray-700">
                        {loan.address}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default RepaymentStatusCard
