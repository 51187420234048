import React, { useEffect } from 'react';
import { supabase } from '../supabaseClient';

const LoanOfferDetailsCard = (props) => {
  const { loanInfo } = props;

  const sessionId = localStorage.getItem('sessionId');

  const totalAmount = parseFloat(loanInfo.totalAmount);
  const rate = loanInfo.apr / 100;

  const monthlyPayment = (totalAmount * (1 + rate / 100)) / loanInfo.termLength;
  const interest = totalAmount * rate / 100;
  const total = totalAmount + parseFloat(interest);

  useEffect(() => {
   
   
    if (!isNaN(monthlyPayment) && sessionId) {
      const updateMonthlyPayment = async () => {
        await supabase
          .from('Applications')
          .update({ total_monthly_payment: formatter.format(monthlyPayment) })
          .eq('session_id', sessionId);
      };

      updateMonthlyPayment();
    }
  }, [monthlyPayment, sessionId]);
  if (isNaN(totalAmount) || isNaN(rate) || isNaN(loanInfo.termLength)) {
    return <div>Loading...</div>;
  }

  

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

 

  return (
    <div className={`relative w-90 bg-white shadow-md border border-gray-300 rounded-lg p-4 pb-5 ${props.className}`}>
      <h2 className="text-lg font-bold text-purple-400 mb-3">${formatter.format(monthlyPayment)}/month</h2>
      <div className="grid grid-cols-3 gap-4 text-center">
        <div>
          <p className="font-semibold">APR</p>
          <p>{rate.toFixed(2)}%</p>
        </div>
        <div>
          <p className="font-semibold">Total Interest</p>
          <p>${formatter.format(interest)}</p>
        </div>
        <div>
          <p className="font-semibold">Total Amount</p>
          <p>${formatter.format(total)}</p>
        </div>
      </div>
      <div className="absolute top-2 right-2 px-5 py-1 text-white text-sm font-bold bg-gray-600 rounded-lg">
        {loanInfo.termLength} Months
      </div>
    </div>
  );
};

export default LoanOfferDetailsCard;
