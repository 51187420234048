import React, { useState, useEffect } from 'react'
import { supabase } from '../supabaseClient'
import axios from 'axios'

const LoanConfirmation = () => {
    const [loanAgreementUrl, setLoanAgreementUrl] = useState('')
    const [docId, setDocId] = useState('')
    const [isAgreed, setIsAgreed] = useState(false)
    const [loanAgreementSigned, setLoanAgreementSigned] = useState(false)

    const sessionId = localStorage.getItem('sessionId')

    const signLoanAgreement = async (agreementId) => {
        const data = {
            session_id: sessionId,
            doc_id: agreementId,
        }

        try {
            const response = await axios.post(
                'https://marley-backend.herokuapp.com/sign-loan-agreement',
                data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )

            if (response.status === 200) {
                setLoanAgreementSigned(true)
            }
            // Handle the response if needed
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Error status:', error.response.status)
                console.error('Error data:', error.response.data)
            } else if (error.request) {
                // The request was made but no response was received
                console.error('No response received:', error.request)
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error updating record:', error.message)
            }
            throw error
        }
    }

    const handleSubmit = async () => {
        if (isAgreed) {
            try {
                await signLoanAgreement(docId)
                // Handle success if needed
            } catch (error) {
                // Handle errors if necessary
            }
        } else {
            // Show an error message or handle the case when the checkbox is not checked
        }
    }

    const handleCheckboxChange = (event) => {
        setIsAgreed(event.target.checked)
    }

    const handleClose = () => {
        window.close()
    }

    const fetchLoanAgreement = async () => {
        const { data, error } = await supabase
            .from('Applications')
            .select('loan_agreement_url, doc_id')
            .eq('session_id', sessionId)
            .single()

        if (error) {
            console.error('Error fetching loan agreement URL:', error)
        } else {
            setLoanAgreementUrl(data.loan_agreement_url)

            setDocId(data.doc_id)
        }
    }

    useEffect(() => {
        fetchLoanAgreement()
    }, [])

    return (
        <>
            {!loanAgreementSigned ? (
                <div className="min-h-screen text-white">
                    <div className="flex flex-col text-white"></div>
                    <div className="flex flex-col items-center justify-center h-full">
                        <div className="w-3/4 text-center">
                            <div className="bg-white text-black p-3 rounded-md shadow-md h-[480px]">
                                <h2 className="text-2xl font-bold mb-3">
                                    Review and Confirm Loan Details
                                </h2>

                                <div className="flex justify-center h-[400px]">
                                    <object
                                        data={loanAgreementUrl}
                                        type="application/pdf"
                                    >
                                        <iframe
                                            src={`https://docs.google.com/viewer?url=${loanAgreementUrl}&embedded=true`}
                                            className="w-full min-w-[600px]"
                                            title='loan-agreement'
                                        ></iframe>
                                    </object>
                                </div>
                            </div>
                            <div className="bg-white py-5 px-9 rounded-lg shadow-md text-black mt-3 text-left">
                                <input
                                    type="checkbox"
                                    name="isAgreed"
                                    checked={isAgreed}
                                    onChange={handleCheckboxChange}
                                    className="mr-4"
                                />

                                <label className="text-sm">
                                    I have carefully read and understood the
                                    entire loan agreement, and I hereby agree to
                                    its terms. Additionally, I consent to the{' '}
                                    <a href="https://www.pier-lending.com/legal">
                                        <strong>
                                            <u>e-sign disclosure</u>
                                        </strong>
                                    </a>{' '}
                                    policy, acknowledging that my electronic
                                    signature holds the same legal validity as a
                                    handwritten signature.
                                </label>
                                <div className="mt-5">
                                    <button
                                        type="submit"
                                        onClick={handleSubmit}
                                        className="text-center w-full py-2 px-4 bg-muted-purple text-white font-semibold rounded-lg shadow-md hover:bg-pink focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 mb-4"
                                    >
                                        Sign Loan Agreement
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="min-h-screen text-white">
                        <div className="flex flex-col text-white"></div>
                        <div className="flex flex-col items-center justify-center h-full">
                            <div className="w-full md:w-3/4 lg:w-1/2 text-center">
                                <div className="bg-white text-black p-2 rounded-md shadow-md">
                                    <h2 className="text-2xl font-bold mb-2">
                                        Loan Confirmation
                                    </h2>

                                    <h1>
                                        Thank you for choosing Marley. You may now close this window.
                                        Please check your email for additional
                                        details regarding your loan.
                                    </h1>
                                </div>

                                <button
                                    className="bg-muted-purple text-white font-bold py-2 px-4 rounded-md mt-6 w-full"
                                    onClick={handleClose}
                                >
                                    Close Page
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default LoanConfirmation
