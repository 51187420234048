import React, { useState } from 'react'
import { supabase } from '../supabaseClient'

const NavBar = ({ session, userProfile }) => {
    const [showDropdown, setShowDropdown] = useState(false)
    let timeoutId

    const handleMouseEnter = () => {
        clearTimeout(timeoutId)
        setShowDropdown(true)
    }

    const handleMouseLeave = () => {
        timeoutId = setTimeout(() => {
            setShowDropdown(false)
        }, 40)
    }

    return (
        <nav className="w-full flex justify-between items-center py-4 px-6">
           {session ? (<a href="/" className="text-4xl font-bold text-white">
                Marley
            </a>) : ''} 
            
            <div>
                {session && userProfile ? (
                    <div
                        className="relative"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <button
                            className="button block text-white"
                            style={{ right: 0 }}
                        >
                            {userProfile[0].username}
                        </button>
                        {showDropdown && (
                            <div
                                className="absolute bg-white shadow-md mt-2 rounded-md w-[155px]"
                                style={{ right: 0 }}
                            >
                                <button
                                    className="block text-left px-4 py-2"
                                    onClick={() =>
                                        (window.location.href = '/loans-view')
                                    }
                                >
                                    View Loans
                                </button>
                                <button
                                    className="block w-full text-left px-4 py-2"
                                    onClick={() => supabase.auth.signOut()}
                                >
                                    Sign Out
                                </button>
                            </div>
                        )}
                    </div>
                ) : null}
            </div>
        </nav>
    )
}

export default NavBar
