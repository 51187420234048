import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import NavBar from './components/NavBar'
import Login from './components/Login'
import Dashboard from './pages/Dashboard'
import Application from './pages/Application'
import Approved from './components/Approved'
import ApplicationPending from './components/ApplicationPending'
import LoanConfirmation from './components/LoanConfirmation'
import { supabase } from './supabaseClient'
import LoansView from './pages/LoansView'


function App() {
    const [userProfile, setUserProfile] = useState(null)
    const [session, setSession] = useState(null)

    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session)
        

            if (session) {
                fetchUserProfile(session.user.id)
            }
        })

        async function fetchUserProfile(id) {
            const { data, error } = await supabase
                .from('profiles')
                .select('*')
                .eq('id', id)

            if (error) {
                console.error('Error fetching user profile:', error)
            } else {
                if (
                    data &&
                    data.length > 0 &&
                    data[0].account_type === 'venue'
                ) {
                    setUserProfile(data)
                } else {
                    console.error('User is not a venue. Logging out...')
                    setUserProfile(null)
                    supabase.auth.signOut()
                }
            }

        }

        supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session)
            if (session) {
                fetchUserProfile(session.user.id)
            } else {
                setUserProfile(null) // Set userProfile to null if no session
            }
        })
    }, [])

    return (
        <Router>
            <div className="App h-screen flex flex-col">
                {session ? (<NavBar session={session} userProfile={userProfile} />) : ''}

                {!session ? (
                    <Login />
                ) : (
                    <div className="App">
                        
                        <Switch>
                            <Route exact path="/" 
                            
                            render={() => (<Dashboard userProfile={userProfile} />)}
                            />
                            <Route
                                exact
                                path="/application"
                                render={() => (
                                    <Application
                                        userProfile={userProfile}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/approved"
                                component={Approved}
                            />
                            <Route
                                exact
                                path="/pending"
                                component={ApplicationPending}
                            />
                            <Route
                                exact
                                path="/loanconfirmation"
                                component={LoanConfirmation}
                                key={Math.random()}
                            />
                            <Route
                                exact
                                path="/loans-view"
                                render={() => (
                                    <LoansView
                                        session={session}
                                        userProfile={userProfile}
                                    />
                                )}
                            />
                            
                        </Switch>
                    </div>
                )}
            </div>
        </Router>
    )
}

export default App
