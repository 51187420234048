import React, { useState, useEffect } from 'react';

const NumberInputCard = ({ value, onChange, placeholder }) => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setInputValue(formatValue(value));
  }, [value]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);

    const numericValue = newValue.replace(/[^0-9.]/g, ''); // Only allow numbers and decimal points
    const decimalCount = (numericValue.match(/\./g) || []).length;
    const limitedValue = decimalCount > 1 ? numericValue.substring(0, numericValue.lastIndexOf('.')) + numericValue.substring(numericValue.lastIndexOf('.') + 1) : numericValue;
    const roundedValue = parseFloat(limitedValue).toFixed(2);
    onChange(roundedValue);
  };

  const formatValue = (value) => {
    if (value === '') {
      return '';
    }
    if(isNaN(value)){
        return '    ';
    }
    const numberValue = parseFloat(value);
    return numberValue.toLocaleString(undefined, { maximumFractionDigits: 2 });
  };

  return (
    <input
      type="text"
      className="w-full bg-transparent text-center text-xl border border-gray py-1 rounded focus:outline-none"
      value={inputValue}
      onChange={handleChange}
      placeholder={placeholder}
    />
  );
};

export default NumberInputCard;
