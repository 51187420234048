// dob / id information validation needed

import React, { useState } from 'react'
import { supabase } from '../supabaseClient'
import NumberInputCard from './NumberInputCard'
import axios from 'axios'

const BasicInfoForm = ({
    onSubmit,
    userProfile,
    onApplicationStatusChange,
}) => {
    const [formStep, setFormStep] = useState(0)
    const [applicationStep, setApplicationStep] = useState(0)
    const [borrowerId, setBorrowerId] = useState('')

    const completeFormStep = () => {
        if (formValues.totalLoanAmount) setFormStep((cur) => cur + 1)
    }

    const completeApplicationStep = () => {
        setApplicationStep((current) => current + 1)
    }

    const sessionId = localStorage.getItem('sessionId')

    const [formValues, setFormValues] = useState({
        totalLoanAmount: '',
        first_name: '',
        last_name: '',
        ssn: '',
        ssn_confirmation: '',
        street_address: '',
        apt_number: '',
        city: '',
        state: '',
        zip_code: '',
        email: '',
        mobile_phone: '',
        isAgreed: '',
        id_type: '',
        id_number: '',
        id_state: '',
        date_of_birth: '',
        venue_name: '',
    })

    const [formErrors, setFormErrors] = useState({})

    const createRecord = async (fields) => {
        try {
            const { data, error } = await supabase
                .from('Applications')
                .update({
                    first_name: fields.first_name,
                    last_name: fields.last_name,
                    ssn_last_four: fields.ssn.slice(-4),
                    street_address: fields.street_address,
                    apt_number: fields.apt_number,
                    city: fields.city,
                    state: fields.state,
                    zip_code: fields.zip_code,
                    email: fields.email,
                    mobile_phone: fields.mobile_phone,
                    total_loan_amount: fields.totalLoanAmount,
                    id_type: fields.id_type,
                    id_number: fields.id_number,
                    id_state: fields.id_state,
                    date_of_birth: fields.date_of_birth,
                    venue_name: userProfile[0].username,
                    term: 12,
                    monthly_principal_payment: (
                        parseInt(fields.totalLoanAmount) / 12
                    ).toString(),
                })
                .eq('session_id', sessionId)

            if (error) {
                console.error('Error updating record:', error)
            } else {
                console.log('')
            }
        } catch (error) {
            console.error('Error updating record:', error)
        }
    }

    const evaluateApplication = async (fields, appId) => {
        const data = {
            offer: {
                amount: parseInt(fields.totalLoanAmount) * 100,
                interest_rate: 1000,
                repayment_frequency: 'monthly',
                term: 12,
                origination_fee: 0,
                late_payment_fee: 0,
                grace_period: 0,
                grace_period_interest_rate: 0,
                third_party_disbursement_destination: JSON.stringify(
                    userProfile[0].username
                ),
            },
            session_id: sessionId,
            application_id: appId,
        }

        try {
            const response = await axios({
                method: 'post',
                url: 'https://marley-backend.herokuapp.com/evaluate-application',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: data,
            })

            onSubmit(response.data.status)
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Error status:', error.response.status)
                console.error('Error data:', error.response.data)
                window.location.href = '/pending'
            } else if (error.request) {
                // The request was made but no response was received
                console.error('No response received:', error.request)
                window.location.href = '/pending'
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error evaluating application:', error.message)
                window.location.href = '/pending'
            }
        }
    }

    const createApplication = async (borrowerId) => {
        try {
            const axiosData = {
                borrower_id: borrowerId,
                credit_type: 'consumer_loan',
                session_id: sessionId,
            }

            const response = await axios({
                method: 'post',
                url: 'https://marley-backend.herokuapp.com/create-application',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: axiosData,
            })

            setTimeout(() => {
                evaluateApplication(formValues, response.data.id)
            }, 250)
        } catch (error) {
            console.error('Error creating application:', error.message)
        }
    }

    const createBorrower = async (fields) => {
        const data = {
            address: {
                line_1: fields.street_address,
                line_2: fields.apt_number,
                city: fields.city,
                state: fields.state,
                zip: fields.zip_code,
            },
            date_of_birth: fields.date_of_birth,
            email: fields.email,
            first_name: fields.first_name,
            last_name: fields.last_name,
            ssn: fields.ssn,
            kyc_completion_date: '2022-11-15',
            session_id: sessionId,
        }

        try {
            const response = await axios({
                method: 'post',
                url: 'https://marley-backend.herokuapp.com/create-borrower',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: data,
            })

            setBorrowerId(response.data.id)
            setTimeout(() => {
                createApplication(response.data.id)
            }, 250)
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Error status:', error.response.status)
                console.error('Error data:', error.response.data)
                window.location.href = '/pending'
            } else if (error.request) {
                // The request was made but no response was received
                console.error('No response received:', error.request)
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error updating record:', error.message)
            }
        }
    }

    const validateForm = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

        const errors = {}

        // Add your validation logic here, for example:
        if (!formValues.totalLoanAmount) {
            errors.totalLoanAmount = 'Enter Total Loan Amount'
        }

        if (!formValues.first_name) {
            errors.first_name = 'First Name is required'
        }

        if (!formValues.last_name) {
            errors.last_name = 'Last Name is required'
        }

        if (!formValues.ssn) {
            errors.ssn = 'Social Security Number is required'
        } else if (!/^\d{9}$/.test(formValues.ssn)) {
            errors.ssn = 'SSN must be a 9-digit number'
        } else if (formValues.ssn !== formValues.ssn_confirmation) {
            errors.ssn = 'SSN and SSN confirmation do not match'
        }

        if (!formValues.ssn_confirmation) {
            errors.ssn_confirmation = 'Please Confirm SSN'
        }

        if (!formValues.street_address) {
            errors.street_address = 'Street Address is required'
        }

        //apt number is optional

        if (!formValues.city) {
            errors.city = 'City is required'
        }

        if (!formValues.state) {
            errors.state = 'State is required'
        }

        if (!formValues.zip_code) {
            errors.zip_code = 'Area Code is required'
        }

        if (!formValues.date_of_birth) {
            errors.date_of_birth = 'Date of birth is required';
          } else {
            // Calculate the minimum allowed date (18 years ago from the current date)
            const today = new Date();
            const minDate = new Date();
            minDate.setFullYear(today.getFullYear() - 18);
          
            // Convert the input value to a Date object
            const selectedDate = new Date(formValues.date_of_birth);
          
            // Check if the selected date is after the minimum allowed date
            if (selectedDate > minDate) {
              errors.date_of_birth = 'Applicants must be at least 18 years old.';
            }
          }

        if (!formValues.email) {
            errors.email = 'Email is required'
        } else if (!emailRegex.test(formValues.email)) {
            errors.email = 'Please enter valid email address'
        }

        if (!formValues.mobile_phone) {
            errors.mobile_phone = 'Phone Number is required'
        } else if (!/^\d{10}$/.test(formValues.mobile_phone)) {
            errors.mobile_phone = 'Please enter a valid phone number'
        }

        if (!formValues.isAgreed) {
            errors.isAgreed =
                'You must agree to the terms before submitting the form.'
        }

        setFormErrors(errors)
        return Object.keys(errors).length === 0
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        const newValue = e.target.type === 'checkbox' ? e.target.checked : value
        setFormValues({ ...formValues, [name]: newValue })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (validateForm()) {
            onSubmit({ formValues })
            createRecord(formValues)
            createBorrower(formValues)
            setApplicationStep(2)
        } else {
            console.log('Form is not valid')
        }
    }

    return (
        <div className="pb-[150px] px-4 md:px-8">
            <div className="flex flex-col text-white">
                <nav className="flex justify-between items-center py-[40px] px-6"></nav>
            </div>

            {applicationStep === 0 && (
                <form onSubmit={handleSubmit} className="space-y-6">
                    {formStep === 0 && (
                        <section>
                            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                                <div className="grid grid-cols-1  gap-4">
                                    {/* Total Loan Amount*/}
                                    <div>
                                        <label
                                            htmlFor="totalLoanAmount"
                                            className="block text-sm font-medium"
                                        >
                                            Total Amount to Finance ($)
                                        </label>
                                        <NumberInputCard
                                            value={formValues.totalLoanAmount}
                                            onChange={(newValue) =>
                                                setFormValues({
                                                    ...formValues,
                                                    totalLoanAmount: newValue,
                                                })
                                            }
                                        />
                                        {formErrors.totalLoanAmount && (
                                            <p className="text-red-500 text-xs mt-1">
                                                {formErrors.totalLoanAmount}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <button
                                className="bg-muted-purple text-white py-2 px-4 rounded w-full  "
                                onClick={completeFormStep}
                            >
                                Continue
                            </button>
                        </section>
                    )}
                    {formStep === 1 && (
                        <section>
                            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    {/* First Name */}
                                    <div>
                                        <label
                                            htmlFor="firstName"
                                            className="block text-sm font-medium"
                                        >
                                            First Name
                                        </label>
                                        <input
                                            type="text"
                                            name="first_name"
                                            value={formValues.first_name}
                                            onChange={handleChange}
                                            className={`block w-full p-2 border rounded ${
                                                formErrors.first_name
                                                    ? 'border-red-500'
                                                    : 'border-gray-300'
                                            }`}
                                        />
                                        {formErrors.first_name && (
                                            <p className="text-red-500 text-xs mt-1">
                                                {formErrors.first_name}
                                            </p>
                                        )}
                                    </div>

                                    {/* Last Name */}
                                    <div>
                                        <label
                                            htmlFor="lastName"
                                            className="block text-sm font-medium"
                                        >
                                            Last Name
                                        </label>
                                        <input
                                            type="text"
                                            name="last_name"
                                            value={formValues.last_name}
                                            onChange={handleChange}
                                            className={`block w-full p-2 border rounded ${
                                                formErrors.last_name
                                                    ? 'border-red-500'
                                                    : 'border-gray-300'
                                            }`}
                                        />
                                        {formErrors.last_name && (
                                            <p className="text-red-500 text-xs mt-1">
                                                {formErrors.last_name}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    {/* SSN Card */}
                                    {/* SSN */}
                                    <div>
                                        <label
                                            htmlFor="ssn"
                                            className="block text-sm font-medium"
                                        >
                                            SSN
                                        </label>
                                        <input
                                            type="password"
                                            name="ssn"
                                            value={formValues.ssn}
                                            onChange={handleChange}
                                            className={`block w-full p-2 border rounded ${
                                                formErrors.ssn
                                                    ? 'border-red-500'
                                                    : 'border-gray-300'
                                            }`}
                                        />
                                        {formErrors.ssn && (
                                            <p className="text-red-500 text-xs mt-1">
                                                {formErrors.ssn}
                                            </p>
                                        )}
                                    </div>

                                    {/* SSN Confirmation */}
                                    <div>
                                        <label
                                            htmlFor="ssn_confirmation"
                                            className="block text-sm font-medium"
                                        >
                                            SSN Confirmation
                                        </label>
                                        <input
                                            type="password"
                                            name="ssn_confirmation"
                                            value={formValues.ssn_confirmation}
                                            onChange={handleChange}
                                            className={`block w-full p-2 border rounded ${
                                                formErrors.ssn_confirmation
                                                    ? 'border-red-500'
                                                    : 'border-gray-300'
                                            }`}
                                        />
                                        {formErrors.ssn_confirmation && (
                                            <p className="text-red-500 text-xs mt-1">
                                                {formErrors.ssn_confirmation}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    {/* Street Address */}
                                    <div>
                                        <label
                                            htmlFor="street_address"
                                            className="block text-sm font-medium"
                                        >
                                            Street Address
                                        </label>
                                        <input
                                            type="text"
                                            name="street_address"
                                            value={formValues.street_address}
                                            onChange={handleChange}
                                            className={`block w-full p-2 border rounded ${
                                                formErrors.street_address
                                                    ? 'border-red-500'
                                                    : 'border-gray-300'
                                            }`}
                                        />
                                        {formErrors.street_address && (
                                            <p className="text-red-500 text-xs mt-1">
                                                {formErrors.street_address}
                                            </p>
                                        )}
                                    </div>

                                    {/* Apartment Number */}
                                    <div>
                                        <label
                                            htmlFor="apt_number"
                                            className="block text-sm font-medium"
                                        >
                                            Apartment Number
                                        </label>
                                        <input
                                            type="text"
                                            name="apt_number"
                                            value={formValues.apt_number}
                                            onChange={handleChange}
                                            className="block w-full p-2 border border-gray-300 rounded"
                                        />
                                    </div>

                                    {/* City */}
                                    <div>
                                        <label
                                            htmlFor="city"
                                            className="block text-sm font-medium"
                                        >
                                            City
                                        </label>
                                        <input
                                            type="text"
                                            name="city"
                                            value={formValues.city}
                                            onChange={handleChange}
                                            className={`block w-full p-2 border rounded ${
                                                formErrors.city
                                                    ? 'border-red-500'
                                                    : 'border-gray-300'
                                            }`}
                                        />
                                        {formErrors.city && (
                                            <p className="text-red-500 text-xs mt-1">
                                                {formErrors.city}
                                            </p>
                                        )}
                                    </div>

                                    {/* State */}
                                    <div>
                                        <label
                                            htmlFor="state"
                                            className="block text-sm font-medium"
                                        >
                                            State
                                        </label>
                                        <select
                                            name="state"
                                            value={formValues.state}
                                            onChange={handleChange}
                                            className={`block w-full p-2 border rounded ${
                                                formErrors.state
                                                    ? 'border-red-500'
                                                    : 'border-gray-300'
                                            }`}
                                        >
                                            <option value="">--</option>
                                            <option value="AL">Alabama</option>
                                            <option value="AK">Alaska</option>
                                            <option value="AZ">Arizona</option>
                                            <option value="AR">Arkansas</option>
                                            <option value="CA">
                                                California
                                            </option>
                                            <option value="CO">Colorado</option>
                                            <option value="CT">
                                                Connecticut
                                            </option>
                                            <option value="DE">Delaware</option>
                                            <option value="FL">Florida</option>
                                            <option value="GA">Georgia</option>
                                            <option value="HI">Hawaii</option>
                                            <option value="ID">Idaho</option>
                                            <option value="IL">Illinois</option>
                                            <option value="IN">Indiana</option>
                                            <option value="IA">Iowa</option>
                                            <option value="KS">Kansas</option>
                                            <option value="KY">Kentucky</option>
                                            <option value="LA">
                                                Louisiana
                                            </option>
                                            <option value="ME">Maine</option>
                                            <option value="MD">Maryland</option>
                                            <option value="MA">
                                                Massachusetts
                                            </option>
                                            <option value="MI">Michigan</option>
                                            <option value="MN">
                                                Minnesota
                                            </option>
                                            <option value="MS">
                                                Mississippi
                                            </option>
                                            <option value="MO">Missouri</option>
                                            <option value="MT">Montana</option>
                                            <option value="NE">Nebraska</option>
                                            <option value="NV">Nevada</option>
                                            <option value="NH">
                                                New Hampshire
                                            </option>
                                            <option value="NJ">
                                                New Jersey
                                            </option>
                                            <option value="NM">
                                                New Mexico
                                            </option>
                                            <option value="NY">New York</option>
                                            <option value="NC">
                                                North Carolina
                                            </option>
                                            <option value="ND">
                                                North Dakota
                                            </option>
                                            <option value="OH">Ohio</option>
                                            <option value="OK">Oklahoma</option>
                                            <option value="OR">Oregon</option>
                                            <option value="PA">
                                                Pennsylvania
                                            </option>
                                            <option value="RI">
                                                Rhode Island
                                            </option>
                                            <option value="SC">
                                                South Carolina
                                            </option>
                                            <option value="SD">
                                                South Dakota
                                            </option>
                                            <option value="TN">
                                                Tennessee
                                            </option>
                                            <option value="TX">Texas</option>
                                            <option value="UT">Utah</option>
                                            <option value="VT">Vermont</option>
                                            <option value="VA">Virginia</option>
                                            <option value="WA">
                                                Washington
                                            </option>
                                            <option value="WV">
                                                West Virginia
                                            </option>
                                            <option value="WI">
                                                Wisconsin
                                            </option>
                                            <option value="WY">Wyoming</option>
                                        </select>
                                        {formErrors.state && (
                                            <p className="text-red-500 text-xs mt-1">
                                                {formErrors.state}
                                            </p>
                                        )}
                                    </div>

                                    {/* Zip Code */}
                                    <div>
                                        <label
                                            htmlFor="zip_code"
                                            className="block text-sm font-medium"
                                        >
                                            Area Code
                                        </label>
                                        <input
                                            type="text"
                                            name="zip_code"
                                            value={formValues.zip_code}
                                            onChange={handleChange}
                                            className={`block w-full p-2 border rounded ${
                                                formErrors.zip_code
                                                    ? 'border-red-500'
                                                    : 'border-gray-300'
                                            }`}
                                        />
                                        {formErrors.zip_code && (
                                            <p className="text-red-500 text-xs mt-1">
                                                {formErrors.zip_code}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    {/* Contact Card */}
                                    {/* Email */}
                                    <div>
                                        <label
                                            htmlFor="email"
                                            className="block text-sm font-medium"
                                        >
                                            Email
                                        </label>
                                        <input
                                            type="text"
                                            name="email"
                                            value={formValues.email}
                                            onChange={handleChange}
                                            className={`block w-full p-2 border rounded ${
                                                formErrors.email
                                                    ? 'border-red-500'
                                                    : 'border-gray-300'
                                            }`}
                                        />
                                        {formErrors.email && (
                                            <p className="text-red-500 text-xs mt-1">
                                                {formErrors.email}
                                            </p>
                                        )}
                                    </div>

                                    {/* Phone Number */}
                                    <div>
                                        <label
                                            htmlFor="mobile_phone"
                                            className="block text-sm font-medium"
                                        >
                                            Phone Number
                                        </label>
                                        <input
                                            type="text"
                                            name="mobile_phone"
                                            value={formValues.mobile_phone}
                                            onChange={handleChange}
                                            className={`block w-full p-2 border rounded ${
                                                formErrors.mobile_phone
                                                    ? 'border-red-500'
                                                    : 'border-gray-300'
                                            }`}
                                        />
                                        {formErrors.mobile_phone && (
                                            <p className="text-red-500 text-xs mt-1">
                                                {formErrors.mobile_phone}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                                <div className="grid grid-cols-3 gap-4">
                                    <div>
                                        <label
                                            htmlFor="id_type"
                                            className="block text-sm font-medium"
                                        >
                                            ID Type
                                        </label>
                                        <select
                                            name="id_type"
                                            value={formValues.id_type}
                                            onChange={handleChange}
                                            className={`block w-full p-2 border rounded ${
                                                formErrors.id_type
                                                    ? 'border-red-500'
                                                    : 'border-gray-300'
                                            }`}
                                        >
                                            <option value="">
                                                Select ID type
                                            </option>
                                            <option value="drivers_license">
                                                Driver's License
                                            </option>
                                            <option value="state_id">
                                                State ID
                                            </option>
                                            <option value="military_id">
                                                Military ID
                                            </option>
                                            <option value="us_passport">
                                                US Passport
                                            </option>
                                            <option value="resident_alien_card">
                                                Resident Alien Card
                                            </option>
                                            <option value="matricula_card">
                                                Matricula Card
                                            </option>
                                        </select>
                                        {formErrors.id_type && (
                                            <p className="text-red-500 text-xs mt-1">
                                                {formErrors.id_type}
                                            </p>
                                        )}
                                    </div>

                                    <div>
                                        <label
                                            htmlFor="id_number"
                                            className="block text-sm font-medium"
                                        >
                                            ID Number
                                        </label>
                                        <input
                                            type="text"
                                            name="id_number"
                                            value={formValues.id_number}
                                            onChange={handleChange}
                                            className={`block w-full p-2 border rounded ${
                                                formErrors.id_number
                                                    ? 'border-red-500'
                                                    : 'border-gray-300'
                                            }`}
                                        />
                                    </div>
                                    <div>
                                        <label
                                            htmlFor="state"
                                            className="block text-sm font-medium"
                                        >
                                            State
                                        </label>
                                        <select
                                            name="id_state"
                                            value={formValues.id_state}
                                            onChange={handleChange}
                                            disabled={
                                                formValues.id_type !==
                                                    'drivers_license' &&
                                                formValues.id_type !==
                                                    'state_id' &&
                                                formValues.id_type !== null
                                            }
                                            // className={`block w-full p-2 border rounded ${
                                            //     formErrors.id_state
                                            //         ? 'border-red-500'
                                            //         : 'border-gray-300'
                                            // }`}
                                            className="block w-full p-2 border rounded"
                                        >
                                            <option value="">--</option>
                                            <option value="AL">Alabama</option>
                                            <option value="AK">Alaska</option>
                                            <option value="AZ">Arizona</option>
                                            <option value="AR">Arkansas</option>
                                            <option value="CA">
                                                California
                                            </option>
                                            <option value="CO">Colorado</option>
                                            <option value="CT">
                                                Connecticut
                                            </option>
                                            <option value="DE">Delaware</option>
                                            <option value="FL">Florida</option>
                                            <option value="GA">Georgia</option>
                                            <option value="HI">Hawaii</option>
                                            <option value="ID">Idaho</option>
                                            <option value="IL">Illinois</option>
                                            <option value="IN">Indiana</option>
                                            <option value="IA">Iowa</option>
                                            <option value="KS">Kansas</option>
                                            <option value="KY">Kentucky</option>
                                            <option value="LA">
                                                Louisiana
                                            </option>
                                            <option value="ME">Maine</option>
                                            <option value="MD">Maryland</option>
                                            <option value="MA">
                                                Massachusetts
                                            </option>
                                            <option value="MI">Michigan</option>
                                            <option value="MN">
                                                Minnesota
                                            </option>
                                            <option value="MS">
                                                Mississippi
                                            </option>
                                            <option value="MO">Missouri</option>
                                            <option value="MT">Montana</option>
                                            <option value="NE">Nebraska</option>
                                            <option value="NV">Nevada</option>
                                            <option value="NH">
                                                New Hampshire
                                            </option>
                                            <option value="NJ">
                                                New Jersey
                                            </option>
                                            <option value="NM">
                                                New Mexico
                                            </option>
                                            <option value="NY">New York</option>
                                            <option value="NC">
                                                North Carolina
                                            </option>
                                            <option value="ND">
                                                North Dakota
                                            </option>
                                            <option value="OH">Ohio</option>
                                            <option value="OK">Oklahoma</option>
                                            <option value="OR">Oregon</option>
                                            <option value="PA">
                                                Pennsylvania
                                            </option>
                                            <option value="RI">
                                                Rhode Island
                                            </option>
                                            <option value="SC">
                                                South Carolina
                                            </option>
                                            <option value="SD">
                                                South Dakota
                                            </option>
                                            <option value="TN">
                                                Tennessee
                                            </option>
                                            <option value="TX">Texas</option>
                                            <option value="UT">Utah</option>
                                            <option value="VT">Vermont</option>
                                            <option value="VA">Virginia</option>
                                            <option value="WA">
                                                Washington
                                            </option>
                                            <option value="WV">
                                                West Virginia
                                            </option>
                                            <option value="WI">
                                                Wisconsin
                                            </option>
                                            <option value="WY">Wyoming</option>
                                        </select>
                                        {/* {formErrors.state && (
                                            <p className="text-red-500 text-xs mt-1">
                                                {formErrors.state}
                                            </p>
                                        )} */}
                                    </div>
                                </div>
                                <div className="grid grid-cols-3 gap-4">
                                    <div className="col-span-1">
                                        <label
                                            htmlFor="date_of_birth"
                                            className="block text-sm font-medium"
                                        >
                                            Date of Birth
                                        </label>
                                        <input
                                            type="date"
                                            name="date_of_birth"
                                            value={formValues.date_of_birth}
                                            onChange={handleChange}
                                            className={`block w-full p-2 border rounded ${
                                                formErrors.date_of_birth
                                                    ? 'border-red-500'
                                                    : 'border-gray-300'
                                            }`}
                                        />
                                        {formErrors.date_of_birth && (
                                            <p className="text-red-500 text-xs mt-1">
                                                {formErrors.date_of_birth}
                                            </p>
                                        )}
                                    </div>
                                    {/* Empty columns */}
                                    <div className="col-span-1"></div>
                                    <div className="col-span-1"></div>
                                </div>
                            </div>
                            {/* Submit Button */}
                            <div className="bg-white p-4 rounded-lg shadow-md">
                                <input
                                    type="checkbox"
                                    name="isAgreed"
                                    checked={formValues.isAgreed}
                                    onChange={handleChange}
                                    className="mr-4" // Add some right margin to the checkbox
                                />
                                <label className="text-sm">
                                    {' '}
                                    {/* Make the label text smaller */}I have
                                    read and agree to our partner Pier's{' '}
                                    <a href="https://www.pier-lending.com/legal">
                                        <strong>
                                            <u>terms of service</u>
                                        </strong>
                                    </a>
                                    ,{' '}
                                    <a href="https://www.pier-lending.com/legal">
                                        <strong>
                                            <u>privacy policy</u>
                                        </strong>
                                    </a>
                                    ,{' '}
                                    <a href="https://www.pier-lending.com/legal">
                                        <strong>
                                            <u>
                                                electronic communications policy
                                            </u>
                                        </strong>
                                    </a>
                                    , and{' '}
                                    <a href="https://www.pier-lending.com/legal">
                                        <strong>
                                            <u>e-sign disclosure</u>
                                        </strong>
                                    </a>
                                    . I understand that by clicking on the AGREE
                                    and APPLY button immediately following this
                                    notice, I am providing “written
                                    instructions” to Pier Lending LLC under the
                                    Fair Credit Reporting Act authorizing Pier
                                    Lending LLC to obtain information from my
                                    personal credit profile or other information
                                    from Reseller. I authorize Pier Lending LLC
                                    to obtain such information solely to
                                    prequalify me for credit options including
                                    unsecured lines of credit and personal
                                    loans. Credit Information accessed for my
                                    pre-qualification request may be different
                                    from the Credit Information accessed by a
                                    credit grantor on a date after the date of
                                    my original prequalification request to make
                                    the credit decision.
                                </label>
                                {formErrors.isAgreed && (
                                    <p className="text-red-500 text-xs mt-1">
                                        {formErrors.isAgreed}
                                    </p>
                                )}
                            </div>
                            <div className="mt-5">
                                <button
                                    type="submit"
                                    onClick={handleSubmit}
                                    className="w-full py-2 px-4 bg-muted-purple text-white font-semibold rounded-lg shadow-md hover:bg-pink focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 mb-4"
                                >
                                    Agree and Apply for Loan
                                </button>
                            </div>

                            <div className="text-sm">
                                <label className="text-neutral-500">
                                    Your loan is provided by our partner, Pier
                                    Lending LLC. All loans and rate terms are
                                    subject to eligibility restrictions
                                    including application review, loan amount,
                                    loan term and lender approval. Eligibility
                                    for a loan is not guaranteed. NMLS #2451164
                                </label>
                            </div>
                        </section>
                    )}
                </form>
            )}

            {/* {applicationStep === 1 && <Approved />}

            {applicationStep === 2 && <ApplicationPending />}

            {applicationStep === 3 && <LoanConfirmation />} */}
        </div>
    )
}

export default BasicInfoForm
