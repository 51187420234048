import React, { useState, useEffect } from 'react'
import { supabase } from '../supabaseClient'
import { useSession } from '../SessionContext'
import NumberInputCard from '../components/NumberInputCard'
import axios from 'axios'

const Dashboard = ({ userProfile }) => {
    const [loanLinkParams, setLoanLinkParams] = useState({
        totalAmount: '',
        sessionID: Date.now().toString(),
        venueName: '',
    })

    const [applicationLink, setApplicationLink] = useState(null)
    const [showInputField, setShowInputField] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [showSendTextField, setShowSendTextField] = useState(false)

    useEffect(() => {
        if (applicationLink !== null) {
        }
    }, [applicationLink])

    const handleCreateApplication = async () => {
        const sessionID = Date.now().toString()
        try {
            const { data, error } = await supabase
                .from('Applications')
                .insert([{ session_id: sessionID }])

            if (error) {
                console.error('Error creating application:', error)
            }

            localStorage.setItem('sessionId', sessionID)
            window.open('/application', '_blank', 'width=850,height=800')
        } catch (error) {
            console.log(error)
        }
    }

    const handleCreateApplicationLink = async () => {
        const sessionID = Date.now().toString()
        setTimeout(
            setLoanLinkParams({ ...loanLinkParams, sessionID: sessionID }),
            50
        )

        try {
            const { data, error } = await supabase.from('Applications').insert([
                {
                    session_id: loanLinkParams.sessionID,
                    venue_name: userProfile[0].username,
                    total_loan_amount: loanLinkParams.totalAmount.toString(),
                },
            ])

            if (error) {
                console.error('Error creating application:', error)
            } else {
                const link =
                    'https://application.marleyfinance.com/new-application/' +
                    loanLinkParams.sessionID +
                    '/' +
                    loanLinkParams.totalAmount +
                    '/' +
                    userProfile[0].username.replace(/\s/g, '')

                setApplicationLink(link)
                setShowInputField(false) // Hide the input field and button
            }
        } catch (error) {
            console.error(error)
        }
    }

    const toggleInputField = () => {
        setShowInputField(!showInputField)
        setShowSendTextField(false)
        setPhoneNumber('')
    }

    const toggleSendTextField = () => {
        setShowSendTextField(!showSendTextField)
    }

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(applicationLink)
        alert('Link copied to clipboard')
    }

    const handleSendText = async () => {
        const accountSid = 'AC96a130c183eaad3e7e0d1221ddafc484'
        const authToken = '48ccfd6bece96f3bde721dad53c4d099'
        const twilioEndpoint = `https://api.twilio.com/2010-04-01/Accounts/${accountSid}/Messages.json`
        const textBody =
            'Click ' + applicationLink + ' to apply for financing with Marley'
        const data = new URLSearchParams()
        data.append('To', phoneNumber)
        data.append('From', '+18777295203')
        data.append('Body', textBody)

        try {
            await axios.post(twilioEndpoint, data, {
                auth: {
                    username: accountSid,
                    password: authToken,
                },
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
            alert('Message sent')
        } catch (error) {
            console.error('Error sending message:', error)
            alert('Error sending message')
        }

        setShowInputField(!showInputField)
        setShowSendTextField(false)
        setPhoneNumber('')
    }

    return (
        <div className="flex flex-col h-screen w-screen text-white">
            <div className="container flex w-full flex-row text-center justify-center pt-5 ml-7"></div>
            <div className="flex-1 flex flex-col items-center justify-center">
                <div className="w-80 align-center text-center bg-gray-900 p-6 rounded-md text-white">
                    <button
                        className="bg-transparent border border-pink text-white py-2 px-4 rounded w-full hover:bg-muted-purple transition-colors duration-500"
                        onClick={toggleInputField}
                    >
                        Create New Application
                    </button>

                    {showInputField && (
                        <div className="mb-4">
                            <h1 className="mt-5 mb-2">
                                Total Amount to Finance ($)
                            </h1>
                            <NumberInputCard
                                type="number"
                                placeholder=""
                                value={loanLinkParams.totalAmount}
                                onChange={(newValue) =>
                                    setLoanLinkParams({
                                        ...loanLinkParams,
                                        totalAmount: newValue,
                                    })
                                }
                                className="w-full bg-gray-800 border text-white py-2 px-3 rounded mb-4 mt-2 text-center"
                            />
                            <div className="">
                                <button
                                    className="bg-blue-400 text-white py-2 px-4 rounded w-full mt-3"
                                    onClick={handleCreateApplicationLink}
                                >
                                    Generate Link
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <div className="mb-2"></div>
                {!showInputField && applicationLink && (
                    <div className="m-3 ">
                        <div className="flex flex-col items-start text-center bg-gray-900 p-6 rounded-md text-white sm:text-sm w-full">
                            <a href={applicationLink} className="">
                                <strong>
                                    <u>{applicationLink}</u>
                                </strong>
                            </a>
                            <div className="flex mt-4 mr-5">
                                <button
                                    className="w-[150px] h-[35px] bg-[#50BEFD] mx-5 rounded-md p-1"
                                    onClick={handleCopyToClipboard}
                                >
                                    Copy to Clipboard
                                </button>
                                {/* to send text message */}
                                <button
                                    className="w-[150px] h-[35px] bg-[#ea6a91] mx-5 rounded-md mx-5 p-1"
                                    onClick={toggleSendTextField}
                                >
                                    Text Link
                                </button>
                                {showSendTextField && (
                                    <div className="">
                                        <input
                                            type="text"
                                            placeholder="Enter phone number"
                                            value={phoneNumber}
                                            onChange={(e) =>
                                                setPhoneNumber(e.target.value)
                                            }
                                            className="bg-gray-800 text-white py-2 px-3 rounded mb-2 mr-3 text-center"
                                        />
                                        <button
                                            className="bg-green-400 text-white py-2 px-4 rounded"
                                            onClick={handleSendText}
                                        >
                                            Send
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Dashboard